var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { t } from "i18next";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loginUser, setLogin } from './actions';
import Button from '../../globals/styles/GlobalButton';
import styled from 'styled-components';
var emailValid = function (value) {
    return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)
        ? false
        : true;
};
function launchToastError(message) {
    toast.error(t(message), {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}
var LeftContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 40%;\n    background-color: #254153;\n    text-align: center;\n    height: 100%;\n"], ["\n    width: 40%;\n    background-color: #254153;\n    text-align: center;\n    height: 100%;\n"])));
var SignInContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    top: 50%;\n    transform: translateY(-50%);\n    position: relative;\n"], ["\n    top: 50%;\n    transform: translateY(-50%);\n    position: relative;\n"])));
var TopLeftContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    height: calc(100% - 50px);\n"], ["\n    height: calc(100% - 50px);\n"])));
var Input = styled.input(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    width: 243px;\n    height: 28px;\n    margin: 8px;\n    border-radius: 6px;\n    padding: 3px;\n    padding-left: 20px;\n\n    &:focus {\n        border-color: white;\n    }\n"], ["\n    width: 243px;\n    height: 28px;\n    margin: 8px;\n    border-radius: 6px;\n    padding: 3px;\n    padding-left: 20px;\n\n    &:focus {\n        border-color: white;\n    }\n"])));
export default function Login() {
    var _a = React.useState(false), showPassword = _a[0], setShowPassword = _a[1];
    var _b = React.useState(""), email = _b[0], setEmail = _b[1];
    var _c = React.useState(""), password = _c[0], setPassword = _c[1];
    var _d = React.useState('loaded'), loadingState = _d[0], setLoadingState = _d[1];
    function onSubmit(e) {
        return __awaiter(this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        e.preventDefault();
                        if (!!email) return [3 /*break*/, 1];
                        launchToastError(t('login.no_email'));
                        return [3 /*break*/, 5];
                    case 1:
                        if (!!emailValid(email)) return [3 /*break*/, 2];
                        launchToastError(t('login.email_not_well_formated'));
                        return [3 /*break*/, 5];
                    case 2:
                        if (!!password) return [3 /*break*/, 3];
                        launchToastError(t('login.no_password'));
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, loginUser({
                            email: email,
                            password: password
                        })];
                    case 4:
                        res = _a.sent();
                        console.log(res);
                        if (res === null || res === void 0 ? void 0 : res.token) {
                            setLoadingState('loaded');
                            setLogin(res);
                            window.location.href = '/';
                        }
                        else {
                            setLoadingState('error');
                            launchToastError('login.wrong_password');
                        }
                        return [2 /*return*/, false];
                    case 5: return [2 /*return*/];
                }
            });
        });
    }
    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            onSubmit({});
        }
    }
    return (_jsxs("div", __assign({ style: { width: '100%' } }, { children: [_jsxs(LeftContainer, { children: [_jsx(TopLeftContainer, { children: _jsxs(SignInContainer, { children: [_jsx("img", { className: "logo-sidely", src: require("/public/images/login/sidely-white-new.png").default, alt: "", height: "50" }), _jsx("br", {}), _jsx("form", __assign({ onSubmit: onSubmit }, { children: _jsxs("div", __assign({ className: "btn-login-form" }, { children: [_jsx(FormInput, { name: "email", type: "email", required: true, placeholder: "Email", value: email, onChange: function (value) { return setEmail(value.target.value); }, onKeyPress: handleKeyPress }), _jsx(FormInput, { name: "password", type: showPassword ? 'text' : 'password', required: true, placeholder: t("login.password"), onKeyPress: handleKeyPress, showPassword: _jsx("i", { onClick: function () { return setShowPassword(!showPassword); }, className: "fas ".concat(showPassword ? 'fa-eye-slash' : 'fa-eye', " pointer"), role: "button", 
                                                    // @ts-ignore
                                                    tabIndex: "0" }), value: password, onChange: function (value) { return setPassword(value.target.value); } }), _jsx("div", __assign({ className: "form-group row form-login-action" }, { children: _jsx("div", __assign({ className: "col-md-12 text-right" }, { children: _jsx(Button, __assign({ className: "btn btn-primary btn-login", onClick: onSubmit }, { children: loadingState != 'loading' && (_jsx("span", __assign({ className: "text-white" }, { children: "Login" }))) })) })) })), _jsxs("div", __assign({ className: "d-flex justify-content-between align-items-center pt-3" }, { children: [_jsxs("label", __assign({ className: "text-white" }, { children: [t("login.no_client_yet"), " ?"] })), _jsx("label", { children: _jsxs("a", __assign({ href: "https://www.go-sidely.com", target: "_blank", style: { color: '#00b2ff' }, rel: "noreferrer" }, { children: [t("login.try_us_for_free"), " !"] })) })] }))] })) }))] }) }), _jsx("div", __assign({ className: "d-flex justify-content-center align-items-end" }, { children: _jsxs("label", { children: ["\u00A9 2021 sidely.fr inc. ", t("login.all_rights_reserved"), ". |", _jsx("a", __assign({ href: "https://www.go-sidely.com/politique-de-confidentialite-sidely", target: "_blank", style: { color: '#00b2ff' }, rel: "noreferrer" }, { children: t("login.confidentiality") }))] }) }))] }), _jsx(ToastContainer, { position: "top-right", autoClose: 10000, hideProgressBar: false, newestOnTop: true, closeOnClick: true, rtl: false, pauseOnFocusLoss: true, draggable: true, pauseOnHover: true })] })));
}
function FormInput(props) {
    var placeholder = props.placeholder, required = props.required, type = props.type, onKeyPress = props.onKeyPress, name = props.name, showPassword = props.showPassword, value = props.value, onChange = props.onChange;
    return (_jsx("div", __assign({ className: "form-group" }, { children: _jsx("div", __assign({ className: "input-group" }, { children: _jsx(Input, { placeholder: placeholder, required: required, type: type, className: "form-control", 
                // @ts-ignore
                onKeyPress: onKeyPress, value: value, onChange: onChange }) })) })));
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
