var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container, LeftContainer, RightContainer } from "./styles/ToolbarStyle";
function Toolbar(props) {
    return (_jsxs(Container, __assign({ backgroundColor: props.backgroundColor ? props.backgroundColor : 'white' }, { children: [props.leftComponent &&
                (_jsx(LeftContainer, { children: props.leftComponent })), props.rightComponent &&
                (_jsx(RightContainer, { children: props.rightComponent }))] })));
}
export default Toolbar;
