var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
document.documentElement.style.setProperty('height', '100%');
document.body.style.height = '100%';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    height: 100%;\n    min-height: 100%;\n    display: flex;\n    flex-direction: row;\n"], ["\n    height: 100%;\n    min-height: 100%;\n    display: flex;\n    flex-direction: row;\n"])));
export default Container;
var templateObject_1;
