var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { PropagateLoader as Loader } from 'react-spinners';
function PropagateLoader() {
    return (_jsx("div", __assign({ id: "preloader" }, { children: _jsx("div", __assign({ className: "d-flex align-items-center justify-content-center h-100" }, { children: _jsx("div", __assign({ className: "sweet-loading" }, { children: _jsx(Loader, { size: 15, color: "#00b2ff", loading: true }) })) })) })));
}
;
export default PropagateLoader;
