export default [
    {
        "logoUrl": require("/public/images/icons/sidebar/dashboard_black.svg"),
        "href": "/",
        "name": "dashboard"
    },
    {
        "logoUrl": require("/public/images/icons/sidebar/reports_black.svg"),
        "href": "/reports",
        "name": "reports"
    },
    {
        logoUrl: require("/public/images/icons/sidebar/company_black.svg"),
        href: "/companies",
        name: "companies"
    },
    {
        logoUrl: require("/public/images/icons/sidebar/alexandria.svg"),
        href: "/alexandria",
        name: "alexandria"
    },
    {
        logoUrl: require("/public/images/icons/sidebar/plans.svg"),
        href: "/plans",
        name: "plans"
    },
    {
        logoUrl: require("/public/images/icons/alexandria/gmap_icon.svg"),
        href: "/mappings",
        name: "mappings"
    }
];
