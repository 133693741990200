import { jsx as _jsx } from "react/jsx-runtime";
import PropagateLoader from '../components/pageLoader/PropagateLoader';
import loadable from '@loadable/component';
var Dashboard = loadable(function () { return import('../containers/dashboard/Dashboard'); }, {
    fallback: _jsx(PropagateLoader, {}),
});
Dashboard.preload();
var Reports = loadable(function () { return import('../containers/reports/Reports'); }, {
    fallback: _jsx(PropagateLoader, {}),
});
var NotFound = loadable(function () { return import('../containers/404/NotFound'); }, {
    fallback: _jsx(PropagateLoader, {}),
});
var Companies = loadable(function () { return import('../containers/companies/Companies'); }, {
    fallback: _jsx(PropagateLoader, {})
});
var Alexandria = loadable(function () { return import('../containers/alexandria/Alexandria'); }, {
    fallback: _jsx(PropagateLoader, {})
});
var Plans = loadable(function () { return import('../containers/plans/Plans'); }, {
    fallback: _jsx(PropagateLoader, {})
});
var Mappings = loadable(function () { return import('../containers/mappings/Mappings'); }, {
    fallback: _jsx(PropagateLoader, {})
});
export default {
    Dashboard: Dashboard,
    Reports: Reports,
    Companies: Companies,
    NotFound: NotFound,
    Alexandria: Alexandria,
    Plans: Plans,
    Mappings: Mappings
};
