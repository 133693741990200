var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var GlobalButton = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background-color: ", ";\n    width: ", ";\n    border-radius: 4px;\n    height: 32px;\n    margin: ", ";\n    color: white;\n    box-shadow: none;\n    border-style: none;\n    cursor: pointer;\n    transition: 0.2s;\n\n    &:disabled {\n        background-color: #D5D5D5;\n        cursor: not-allowed;\n    }\n}\n"], ["\n    background-color: ", ";\n    width: ", ";\n    border-radius: 4px;\n    height: 32px;\n    margin: ", ";\n    color: white;\n    box-shadow: none;\n    border-style: none;\n    cursor: pointer;\n    transition: 0.2s;\n\n    &:disabled {\n        background-color: #D5D5D5;\n        cursor: not-allowed;\n    }\n}\n"])), function (p) { return p.error ? '#f7685b' : '#00b2ff'; }, function (_a) {
    var width = _a.width;
    return width !== null && width !== void 0 ? width : '270px';
}, function (p) { var _a; return (_a = p.margin) !== null && _a !== void 0 ? _a : '10px'; });
export default GlobalButton;
var templateObject_1;
