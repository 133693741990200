var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { DefaultText } from "../../../globals/styles/GlobalText";
var Title = styled.h4(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    ", "\n    color: black;\n    margin: 0;\n    padding: 0;\n    padding: 10px;\n    margin-left: 20px;\n"], ["\n    ", "\n    color: black;\n    margin: 0;\n    padding: 0;\n    padding: 10px;\n    margin-left: 20px;\n"])), DefaultText);
var ImageUser = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    border-radius: 20px;\n    width: 35px;\n    margin-right: 20px;\n"], ["\n    border-radius: 20px;\n    width: 35px;\n    margin-right: 20px;\n"])));
export { Title, ImageUser };
var templateObject_1, templateObject_2;
