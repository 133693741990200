var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Toolbar from "../../components/toolbar/Toolbar";
import logout from "../login/logout";
import { ImageUser, Title } from "./styles/ToolbarStyle";
function getTitle(title) {
    return (_jsx(Title, { children: title }));
}
function getUser() {
    return (_jsx("div", __assign({ style: { cursor: 'pointer' }, onClick: logout }, { children: _jsx(ImageUser, { src: require('../../../public/images/icons/toolbar/no_user.svg').default }) })));
}
function FirstToolbar(props) {
    return (_jsx(Toolbar, { backgroundColor: "#f5f6f8", leftComponent: getTitle(props.title), rightComponent: getUser() }));
}
export default FirstToolbar;
