var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background-color: #f9f9fb;\n    width: calc(100vw - 50px);\n    height: 100vh;\n"], ["\n    background-color: #f9f9fb;\n    width: calc(100vw - 50px);\n    height: 100vh;\n"])));
var Header = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
var Body = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    padding-top: 10px;\n    padding-left: 14px;\n    padding-right: 10px;\n    border-top-right-radius: 10px;\n    border-top-left-radius: 10px;\n"], ["\n    padding-top: 10px;\n    padding-left: 14px;\n    padding-right: 10px;\n    border-top-right-radius: 10px;\n    border-top-left-radius: 10px;\n"])));
export { Container, Header, Body };
var templateObject_1, templateObject_2, templateObject_3;
