import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import FirstToolbar from "./containers/toolbar/FirstToolbar";
import SecondToolbar from "./containers/toolbar/SecondToolbar";
import { Body, Container, Header } from "./MainPageStyle";
function MainPage(props) {
    var _a, _b, _c;
    var _d = React.useState({ title: '', bottomRightToolbarComponent: _jsx(_Fragment, {}), bottomLeftToolbarComponent: _jsx(_Fragment, {}) }), toolBarState = _d[0], setToolBarState = _d[1];
    var newContent = React.cloneElement(props.content, { toolBarState: toolBarState, setToolBarState: setToolBarState });
    return (_jsxs(Container, { children: [_jsxs(Header, { children: [_jsx(FirstToolbar, { title: (_a = toolBarState.title) !== null && _a !== void 0 ? _a : '' }), _jsx(SecondToolbar, { leftComponent: (_b = toolBarState.bottomLeftToolbarComponent) !== null && _b !== void 0 ? _b : _jsx(_Fragment, {}), rightComponent: (_c = toolBarState.bottomRightToolbarComponent) !== null && _c !== void 0 ? _c : _jsx(_Fragment, {}) })] }), _jsx(Body, { children: newContent })] }));
}
export default MainPage;
