var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background-color: ", ";\n    width: 100%;\n    min-height: 60px;\n    display: flex;\n    align-items: center;\n    z-index: 10;\n"], ["\n    background-color: ", ";\n    width: 100%;\n    min-height: 60px;\n    display: flex;\n    align-items: center;\n    z-index: 10;\n"])), function (p) { return p.backgroundColor ? p.backgroundColor : 'white'; });
var LeftContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: block;\n    margin-right: auto;\n    margin-left: 0;\n"], ["\n    display: block;\n    margin-right: auto;\n    margin-left: 0;\n"])));
var RightContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: block;\n    margin-right: 0;\n    margin-left: auto;\n"], ["\n    display: block;\n    margin-right: 0;\n    margin-left: auto;\n"])));
export { Container, LeftContainer, RightContainer };
var templateObject_1, templateObject_2, templateObject_3;
