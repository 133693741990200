import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './routes/App';
import './languages/i18';
import GlobalStyle from './globals/styles/GlobalStyle';
var root = document.getElementById('root');
root.style.height = '100%';
var linkNode = document.createElement('link');
linkNode.rel = 'stylesheet';
linkNode.href = 'https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap';
document.head.appendChild(linkNode);
ReactDOM.render(_jsxs(React.StrictMode, { children: [_jsx(GlobalStyle, {}), _jsx(App, {})] }), root);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
