import * as React from 'react';
import { LoadingState } from './GlobalState';
/**
 * Same as React.useState but apply a function whenever the state is updated
 * The function is not called when the state is initialized
 * @param  {S} initialState The initial state
 * @param  {(s: { oldValue: S, newValue: S }) => S} fn Function called every time the state is updated
 * This function has 2 parameters, the old value, and the new one.
 * The function return the new state
 * @return {[S, React.Dispatch<React.SetStateAction<S>>]} The same as React.useState
 */
export function useFunctionState(initialState, fn) {
    var _a = React.useState(initialState), state = _a[0], setState_ = _a[1];
    var setState = React.useCallback(function (input) { return setState_(function (oldValue) {
        var newValue;
        if (typeof input === 'function') {
            newValue = input(oldValue);
        }
        else {
            newValue = input;
        }
        return fn ? fn({ oldValue: oldValue, newValue: newValue }) : newValue;
    }); }, [fn]);
    return [state, setState];
}
/**
 * Same as useFunctionState but doesn't return the value of the state but a mutable reference on it
 * @see useFunctionState for implementation
 * @param  {S} initialState The initial state
 * @param  {(s: { oldValue: S, newValue: S }) => S} fn Function called every time the state is updated
 * This function has 2 parameters, the old value, and the new one.
 * The function return the new state
 * @return {[S, React.Dispatch<React.SetStateAction<S>>]} The same as React.useState
 */
export function useRefState(initialState, fn) {
    var _a = useFunctionState(initialState, React.useCallback(function (p) {
        var value = p.newValue;
        if (fn)
            value = fn(p);
        state.current = value;
        return value;
    }, [fn])), state_ = _a[0], setState = _a[1];
    var state = React.useRef(state_);
    return [state, setState, state_];
}
/**
 * Load the script given in params and return the state
 * @param  {string} url The script's url
 * @return {LoadingState} The current state of the script
 */
export var useExternalScript = function (url) {
    var _a = React.useState(url ? LoadingState.LOADING : LoadingState.ERROR), state = _a[0], setState = _a[1];
    React.useEffect(function () {
        if (!url) {
            setState(LoadingState.ERROR);
            return;
        }
        var script = document.querySelector("script[src=\"".concat(url, "\"]"));
        var handleScript = function (e) {
            setState(e.type === 'load' ? LoadingState.LOADED : LoadingState.ERROR);
        };
        if (!script) {
            script = document.createElement('script');
            // @ts-expect-error it works
            script.type = 'application/javascript';
            // @ts-expect-error it works
            script.src = url;
            // @ts-expect-error it works
            script.async = true;
            document.body.appendChild(script);
        }
        script.addEventListener('load', handleScript);
        script.addEventListener('error', handleScript);
        return function () {
            script === null || script === void 0 ? void 0 : script.removeEventListener('load', handleScript);
            script === null || script === void 0 ? void 0 : script.removeEventListener('error', handleScript);
        };
    }, [url]);
    return state;
};
