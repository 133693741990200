var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container, Item, ItemLogo, ItemsContainer, Logo } from './styles/SidebarStyle';
import ReactTooltip from 'react-tooltip';
import { t } from 'i18next';
import sidelyLogo from '../../../public/images/icons/sidely-icon.png';
import SidebarData from './data/SidebarData';
function SideBar() {
    return (_jsxs(Container, { children: [_jsx(Logo, { src: sidelyLogo, alt: 'sidely_logo' }), _jsx(ItemsContainer, { children: SidebarData.map(function (data) {
                    return (_jsxs("div", { children: [_jsx(Item, __assign({ "data-tip": t("sidebar.".concat(data.name)), href: data.href }, { children: _jsx(ItemLogo, { src: data.logoUrl.default, alt: 'sidebar_icon' }) })), _jsx(ReactTooltip, { place: 'right', effect: 'solid', delayShow: 400, type: 'light', arrowColor: "#00b2ff", textColor: "white", backgroundColor: '#00b2ff' })] }));
                }) })] }));
}
export default SideBar;
