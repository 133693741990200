var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
var dashboard_fr = require('./fr/dashboard.json');
var reports_fr = require('./fr/reports.json');
var sidebar_fr = require('./fr/sidebar.json');
var dashboard_en = require('./en/dashboard.json');
var reports_en = require('./en/reports.json');
var sidebar_en = require('./en/sidebar.json');
i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
    resources: {
        en: {
            translations: __assign(__assign(__assign({}, dashboard_en), reports_en), sidebar_en)
        },
        fr: {
            translations: __assign(__assign(__assign({}, dashboard_fr), reports_fr), sidebar_fr)
        }
    },
    fallbackLng: "en",
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: '.',
    interpolation: {
        escapeValue: false
    }
});
export default i18n;
