import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useCookies } from 'react-cookie';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SideBar from '../components/sidebar/Sidebar';
import Login from '../containers/login/login';
import MainPage from '../MainPage';
import Container from './AppStyle';
import RoutesPath from './Routes';
import { useExternalScript } from '../globals/utils/customHooks';
// axios interceptor
// Logout whenever a 401 is handled
// axios.interceptors.response.use((response) => {
//   return response;
// }, async (error) => {
//   logout();
//   return Promise.reject(error);
// });
function App() {
    var cookies = useCookies(['cookie-name'])[0];
    var _ = useExternalScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyCYkQC599NV99WgBGvW2RHABKHXowlisgw&libraries=places');
    console.log(cookies);
    //id_token
    return (_jsx(Container, { children: !cookies['id_token'] ? _jsx(Login, {}) : (_jsxs(_Fragment, { children: [_jsx(SideBar, {}), _jsx(BrowserRouter, { children: _jsxs(Routes, { children: [_jsx(Route, { path: '/', element: _jsx(MainPage, { content: _jsx(RoutesPath.Dashboard, {}) }) }), _jsx(Route, { path: 'reports', element: _jsx(MainPage, { content: _jsx(RoutesPath.Reports, {}) }) }), _jsx(Route, { path: 'companies', element: _jsx(MainPage, { content: _jsx(RoutesPath.Companies, {}) }) }), _jsx(Route, { path: 'alexandria', element: _jsx(MainPage, { content: _jsx(RoutesPath.Alexandria, {}) }) }), _jsx(Route, { path: 'plans', element: _jsx(MainPage, { content: _jsx(RoutesPath.Plans, {}) }) }), _jsx(Route, { path: 'mappings', element: _jsx(MainPage, { content: _jsx(RoutesPath.Mappings, {}) }) }), _jsx(Route, { path: '*', element: _jsx(MainPage, { content: _jsx(RoutesPath.NotFound, {}) }) })] }) })] })) }));
}
export default App;
