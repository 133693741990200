"use strict";
// @ts-ignore
module.exports = process.env.NODE_ENV === 'production' ? (function () {
    var env = document.location.hostname.split('.').reverse()[2];
    return {
        URL_API: "https://api.".concat(env, ".sidely.app"),
        URL_API_BUILDER: "https://form.".concat(env, ".sidely.app"),
        URL_FOUNDATION: "https://foundation.".concat(env, ".sidely.app"),
        URL_FOUNDATION_WS: "wss://foundation.".concat(env, ".sidely.app/ws/"),
        URL_CERBERUS: "https://cerberus.".concat(env, ".sidely.app"),
        URL_JANUS: "https://janus.".concat(env, ".sidely.app"),
        URL_SIDELY: "https://".concat(env, ".sidely.app"),
        URL_ALEXANDRIA: "https://alexandria.".concat(env, ".sidely.app"),
        HUBSPOT_CLIENT_ID: "d17afb5c-bf0a-4b7b-9589-6e01cf152b68"
    };
})() : {
    // @ts-ignore
    URL_API: process.env.URL_API || 'https://api.go-sidely.com',
    // @ts-ignore
    URL_FOUNDATION: process.env.URL_FOUNDATION || 'https://foundation.go-sidely.com',
    // @ts-ignore
    URL_CERBERUS: process.env.URL_CERBERUS || 'https://cerberus.go-sidely.com',
    // @ts-ignore
    URL_FOUNDATION_WS: process.env.URL_FOUNDATION_WS || 'wss://foundation.go-sidely.com/ws/',
    // @ts-ignore
    URL_API_BUILDER: process.env.URL_API_BUILDER || 'https://form.go-sidely.com',
    // @ts-ignore
    URL_JANUS: process.env.URL_JANUS || 'https://janus.go-sidely.com',
    // @ts-ignore
    URL_SIDELY: process.env.URL_SIDELY || 'https://app.go-sidely.com',
    // @ts-ignore
    URL_ALEXANDRIA: process.env.URL_ALEXANDRIA,
    // @ts-ignore
    HUBSPOT_CLIENT_ID: process.env.HUBSPOT_CLIENT_ID || 'fea77b7e-36ce-42ec-9e3c-6064eff205b2',
};
